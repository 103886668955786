import { Settings } from "../../app/modules/shared/interfaces/settings";

export const environment: Settings = {
  isProduction: false,
  dataProtectionUrl: 'https://enstroga.de/wp-content/uploads/ENSTROGA_Datenschutz_EB.pdf', // TODO: wenn die Webseite wieder online ist, muss die URL durch die ursprüngliche STROGON-URL ersetzt werden => https://strogon.de/wp-content/uploads/Strogon_Datenschutz_EB.pdf
  dataProtectionWebUrl: 'https://strogon.de/rechtliches/datenschutz/',
  legalNoticeUrl: 'https://enstroga.de/rechtliches/impressum/', // TODO: wenn die Webseite wieder online ist, muss die URL durch die ursprüngliche STROGON-URL ersetzt werden => https://strogon.de/rechtliches/impressum/
  homePageUrl: 'https://strogon.de/',
  faqUrl: 'https://service.enstroga.de/faq', // TODO: Wenn die FAQ online geht, sollte die URL durch die Original-URL STROGON ersetzt werden => https://service.strogon.de/faq
  membershipApplicationFormUrl: 'https://enstroga.de/wp-content/uploads/ENSTROGA_Vertragspartnerdaten.pdf', // TODO: wenn die Webseite wieder online ist, muss die URL durch die ursprüngliche STROGON-URL ersetzt werden => https://strogon.de/wp-content/uploads/Strogon_Vertragspartnerdaten.pdf
  electricityLabelingUrl: 'https://enstroga.de/unternehmen/stromkennzeichnung/', // TODO: wenn die Webseite wieder online ist, muss die URL durch die ursprüngliche STROGON-URL ersetzt werden => https://strogon.de/faq/strom-gas/woher-kommt-mein-strom/
  copyrightBy: 'STROGON',
  hotline: '0228 929 535 55',
  brandName: 'STROGON',
  brandEmail: 'info@strogon.de',
  customerPortalUrl: 'https://service.strogon.de/meter-reading/',
  dashboardUrl: 'dashboard',
  moveUrl: 'move',
  moveSuccessUrl: 'success',
  loginUrl: 'login',
  magicKeyUrl: 'magic-key',
  magicKeyCreateUrl: 'create',
  meterReadingUrl: 'meter-reading',
  monthlyPaymentUrl: 'monthly-payment',
  contactFormUrl: 'contact',
  contractOverviewUrl: 'contract-overview',
  contactMessageSent: 'success',
  paymentInformationUrl: 'payment-information',
  documentsUrl: 'documents',
  orderCorrectionUrl : 'order-correction',
  minimumLoadingTimeInMilliseconds: 400,
  minimumProgressTimeInMilliseconds: 800,
  maximumFileLengthInBytes: 5242880,
   acceptedFileUploadExtensions: ['.png', '.jpg', '.jpeg', '.pdf'],
  creditSettlementUrl: 'credit-settlement',
  showWaiveBalance: false,
  tariffUrl: 'tariff',
  mustSelectPayoutMethodForEachCreditSettlement: true,
  accountStatementUrl: 'account-statement',
  isContractOverviewCardVisible: true,
  isMoveCardVisible: true,
  isMeterReadingCardVisible: true,
  isMonthlyPaymentCardVisible: true,
  isPaymentInformationCardVisible: true,
  isDocumentsCardVisible: true,
  isCreditSettlementCardVisible: true,
  isAccountStatementCardVisible: true,
  isContactCardVisible: true,
  isExternalContactFormUrl: false,
  canCorrectOrder: true,
  showDayaheadPriceOverviewInLogin: false,
  dayaheadPriceOverviewCountry: '#',
  hasOtherPriceComponents: false,
};
